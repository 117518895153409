import React from 'react';

import { TickerCard } from '../../library/ticker-card/TickerCard';
import { TickerProps } from '../../../types/analytics';
import { euroFormat,  } from '../../../utils/format-currency';

export const OpportunitiesTicker = ({ value,subtitle,percent }: TickerProps) =>
  <TickerCard
    title='Cash'
    subtitle={subtitle}
    icon='toolbox'
    value={value}
    formatValue={euroFormat}
    percentage={percent}
    showPercentage = {false}
  />;
