import TabPanel, { Item as TabPanelItem } from 'devextreme-react/tab-panel';
import { Button, Popup } from 'devextreme-react';
import { SegmentInfoUpdate } from './infoUpdate';
import SegmentProductList from './productList';
import SegmentCustomerList from './customerList';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SegmentProductGroupsList from './productGroupsList';
import SegmentBrandsList from './brandsList';


const SegmentPopup = ({ rowData,isPanelOpened,isNewRecord,changePanelOpened,mainRefresh,openNewRow,rowDataUpdate }) => {

    const [segmentProductListRefresh, setSegmentProductListRefresh] = useState(0);
    const [readOnly, setReadOnly] = useState(true);
    const[expandTable, setExpandTable] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);


    useEffect(()=>{
        if(isPanelOpened){
            setSelectedTab(0);
            setExpandTable(false)
            if(isNewRecord){
                setReadOnly(false)
            }else{
                setReadOnly(true)
            }
        }
    },[isPanelOpened]);

    const readOnlyTrue = useCallback(() => {
        setReadOnly(true);
    }, [readOnly]);

    const readOnlyFalse = useCallback(() => {
        setReadOnly(false);
    }, [readOnly]);

    const {t} = useTranslation();

    return (

            <Popup
                key={rowData.segmentRef}
                visible={isPanelOpened}
                hideOnOutsideClick={true}
                title={rowData.segmentName}
                showCloseButton={true}
                width={"95%"}
                height={"95%"}
                onHiding={changePanelOpened}
                titleRender={()=>(
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-row">
                      <Button icon="close" stylingMode="text" onClick={changePanelOpened}></Button>
                      <p className="ml-2 mt-0 justify-center font-bold text-lg">{`${rowData?.segmentName}`}</p>
                      </div>

                    </div>
                  )}
                contentRender={() => (
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    {!expandTable&&(
                        <div style={{position: 'sticky', top: 0,zIndex: 1,paddingBottom: '10px'}}>
                        <SegmentInfoUpdate rowDataUpdate={rowDataUpdate} openNewRow={openNewRow} isPanelOpened={isPanelOpened} changePanelOpened={changePanelOpened} isNewRecord={isNewRecord} readOnly={readOnly} readOnlyTrue={readOnlyTrue} readOnlyFalse={readOnlyFalse} mainRefresh={mainRefresh} segment={rowData} setSegmentProductListRefresh={setSegmentProductListRefresh}></SegmentInfoUpdate>
                        </div>
                    )}
                    <div style={{ flex: 1, overflowY: 'auto',marginTop: '-10px' }}>
                    <TabPanel
                            selectedIndex={selectedTab} // İlk sekme seçili
                            onSelectionChanged={(e) => {
                                const selectedIndex = e.component.option('selectedIndex');
                                if (typeof selectedIndex == 'number') {
                                    setSelectedTab(selectedIndex); // undefined kontrolü yapıyoruz
                                }
                            }}
                        >
                        <TabPanelItem title={t("customers")} disabled={!readOnly || rowData.customerSelectionType == 1}>
                            <SegmentCustomerList expandTable={expandTable} setExpandTable={setExpandTable} readOnly={readOnly}  segmentRef={rowData.segmentRef}/>
                        </TabPanelItem>
                        <TabPanelItem title={t("products")} disabled={!readOnly || rowData.productSelectionType == 1}>
                            <SegmentProductList expandTable={expandTable} setExpandTable={setExpandTable} readOnly={readOnly}  segmentRef={rowData.segmentRef} segmentProductListRefresh={segmentProductListRefresh}></SegmentProductList>
                        </TabPanelItem>
                        <TabPanelItem title={t("productGroups")} disabled={!readOnly || rowData.productGroupSelectionType == 1}>
                            <SegmentProductGroupsList expandTable={expandTable} setExpandTable={setExpandTable} readOnly={readOnly} segmentRef={rowData.segmentRef}/>
                        </TabPanelItem>
                        <TabPanelItem title={t("brands")} disabled={!readOnly || rowData.brandSelectionType == 1}>
                            <SegmentBrandsList expandTable={expandTable} setExpandTable={setExpandTable} readOnly={readOnly} segmentRef={rowData.segmentRef}/>
                        </TabPanelItem>

                    </TabPanel>
                    </div>
                </div>
                )}
                />
    );
};

export default SegmentPopup;















