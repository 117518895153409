import { appInfo } from "./app-info"

export const getNavigation  = (t)=> {
  if(appInfo().menu == "akar"){
    return [
      {
        text: t('analytics'),
        icon: 'chart',
        path: '',
        items: [
          {
            text: t('dashboard'),
            path: '/analytics-dashboard',
          },
        ],
      },
      {
        text: t('sales'),
        icon: 'money',
        path: '',
        items: [

          {
            text: t('salesPersons'),
            path: '/sales-persons',
          },

          {
            text: t('routes'),
            path: '/sales-routes',
          },

        ],
      },
      {
        text: t('general'),
        icon: 'toolbox',
        path: '',
        items: [
          {
            text: t('campaignGroups'),
            path: '/general-campaign-groups',
          },
          {
            text: t('campaigns'),
            path: '/general-campaigns',
          },
          {
            text: t('segmentation'),
            path: '/general-segmentations',
          },
          {
            text: t('incentives'),
            path: '/general-incentives',
          },
          {
            text: t('newProducts'),
            path: '/general-new-products',
          },
          {
            text: t('productImages'),
            path: '/general-images',
          },
        ],
      },
    ];
  } else
  if(appInfo().menu == "beta"){
  return [
    {
    text: t('analytics'),
    icon: 'chart',
    path: '',
    items: [
      {
        text: t('dashboard'),
        path: '/analytics-dashboard',
      },
    ],
  },
  {
    text: t('sales'),
    icon: 'money',
    path: '',
    items: [

      {
        text: t('salesPersons'),
        path: '/sales-persons',
      },

      {
        text: t('routes'),
        path: '/sales-routes',
      },

    ],
  },
  {
    text: t('general'),
    icon: 'toolbox',
    path: '',
    items: [
      {
        text: t('campaignGroups'),
        path: '/general-campaign-groups',
      },

      {
        text: t('campaigns'),
        path: '/general-campaigns',
      },
      {
        text: t('segmentation'),
        path: '/general-segmentations',
      },
      {
        text: t('incentives'),
        path: '/general-incentives',
      },
      {
        text: t('newProducts'),
        path: '/general-new-products',
      },
      {
        text: t('productImages'),
        path: '/general-images',
      },


    ],
  },
  {
    text: 'E-Commerce',
    icon: 'user',
    path: '',
    items: [

      {
        text: t('customerGroups'),
        path: '/b2b-customer-groups',
      },

      {
        text: t('customers'),
        path: '/b2b-customer-list',
      },

      {
        text: t('users'),
        path: '/b2b-customer-users',
      },



    ],
  },

];
}
  else{
    return [
        {
        text: t('analytics'),
        icon: 'chart',
        path: '',
        items: [
          {
            text: t('dashboard'),
            path: '/analytics-dashboard',
          },
          {
            text: t('salesReport'),
            path: '/analytics-sales-report',
          },
        ],
      },
      {
        text: t('sales'),
        icon: 'money',
        path: '',
        items: [

          {
            text: t('salesPersons'),
            path: '/sales-persons',
          },

          {
            text: t('routes'),
            path: '/sales-routes',
          },

        ],
      },
      {
        text: t('general'),
        icon: 'toolbox',
        path: '',
        items: [
          {
            text: t('campaignGroups'),
            path: '/general-campaign-groups',
          },
          {
            text: t('campaigns'),
            path: '/general-campaigns',
          },
          {
            text: t('segmentation'),
            path: '/general-segmentations',
          },
          {
            text: t('incentives'),
            path: '/general-incentives',
          },
          {
            text: t('newProducts'),
            path: '/general-new-products',
          },
          {
            text: t('productImages'),
            path: '/general-images',
          },


        ],
      },
      {
        text: 'E-Commerce',
        icon: 'user',
        path: '',
        items: [

          {
            text: t('customerGroups'),
            path: '/b2b-customer-groups',
          },

          {
            text: t('customers'),
            path: '/b2b-customer-list',
          },

          {
            text: t('userGroups'),
            path: '/b2b-user-groups',
          },

          {
            text: t('users'),
            path: '/b2b-customer-users',
          },
          {
            text: t('userRegister'),
            path: '/b2b-registrations',
          },
          {
            text: t('Order Payments'),
            path: '/finance-transaction',
          },



        ],
      },
      {
        text: 'Finance',
        icon: 'money',
        path: '',
        items: [

          {
            text: t('Open Invoices'),
            path: '/finance-payments',
          },

          {
            text: t('Finapi'),
            path: '/finance-finapi',
          },

        ],
      },

      {
        text: t('exports'),
        icon: 'export',
        path: '',
        items: [
          {
            text: t('datev'),
            path: '/erp-exports',
          },

        ],
      },

    ];
  }
}
