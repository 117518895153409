import React, { useCallback, useEffect, useState } from 'react';
import { Item } from 'devextreme-react/toolbar';
import Tabs from 'devextreme-react/tabs';
import { LoadPanel } from 'devextreme-react/load-panel';
import ScrollView from 'devextreme-react/scroll-view';
import { useScreenSize } from '../../utils/media-query';
import {
  ToolbarAnalytics,
  RevenueSnapshotCard,
  RevenueCard,
  ConversionTicker,
  LeadsTicker,
  OpportunitiesTicker,
  RevenueTotalTicker,
} from '../../components';
import {  ANALYTICS_PERIODS, DEFAULT_ANALYTICS_PERIOD_KEY, SalesDatum, calculatePeriod } from '../../shared/constants';
import { SaleOrOpportunityByCategory } from '../../types/analytics';
import './analytics-dashboard.scss';
import { dashboard2Request } from '../../api/requests';
import { useTranslation } from 'react-i18next';
import { euroFormat } from '../../utils/format-currency';
import CitiesGraph from '../../components/utils/cities-graph/CitiesGraph';
import ReturnsGraph from '../../components/utils/returns-graph/ReturnsGraph';
import SalesGraph from '../../components/utils/sales-graph/SalesGraph';
import BarGraph from '../../components/utils/bar-graph/bar-graph';
import DashboardChart1 from '../../components/library/dashboard/chart1';
import DashboardChart2 from '../../components/library/dashboard/chart2';




export const AnalyticsDashboard = () => {
  const { t } = useTranslation();

   const ANALYTICS_PERIODS2 = {
   [t("day")]: {
      period: calculatePeriod(1),
      index: 0,
    },
    [t("week")]: {
      period: calculatePeriod(7),
      index: 1,
    },
    [t("month")]: {
      period: calculatePeriod(30),
      index: 2,
    },
    [t("year")]: {
      period: calculatePeriod(365),
      index: 3,
    }
  };
  const items = Object.keys(ANALYTICS_PERIODS);
  const [tabIndex, setTabIndex] = useState(3);
  const [dateRange, setDateRange] = useState(ANALYTICS_PERIODS["Week"].period.split('/'));
  const [productTotals, setProductTotals] = useState<SaleOrOpportunityByCategory[]>([]);
  const [salesByCategory, setSalesByCategory] = useState<SaleOrOpportunityByCategory[]>([]);
  const [currentSales, setCurrentSales] = useState<SalesDatum[]>([]);
  const [prevSales, setPrevSales] = useState<SalesDatum[]>([]);
  const [cityData, setCityData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [salesTotal, setSalesTotal] = useState(0);
  const [prevSalesTotal, setPrevSalesTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [tabsWidth, setTabsWidth] = useState<number | string>('auto');

  var tabloShow = true;
  const defaultDateRange = ANALYTICS_PERIODS["Week"].period.split('/').map((d) => new Date(d));
  const [dateRange2, setDateRange2] = useState(defaultDateRange);
  const { isXSmall } = useScreenSize();

  const [cashOnHand1, setCashOnHand1] = useState(0);
  const [bankBalance1, setBankBalance1] = useState(0);
  const [currOrderTotals1, setCurrOrderTotals1] = useState(0);
  const [prevOrderTotals1, setPrevOrderTotals1] = useState(0);

  const [customers, setCustomers] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [curReturns, setCurReturns] = useState([]);
  const [prevReturns, setPrevReturns] = useState([]);

  useEffect(() => {
      update2(tabIndex);
  }, []);

  function update2(index){
      dashboard2Request(index).then((apiData)=>{
        setIsLoading(false);
        const revenueTotal = apiData.filter(item => item.lineType == 1);
        const salesTotall = apiData.filter(item => item.lineType == 2);
        const prevData = apiData["previousRevenue"]??[];

        setCustomers(apiData.filter(item => item.lineType == 8 && item.reportingPeriod == 1));
        setSellers(apiData.filter(item => item.lineType == 9 && item.reportingPeriod == 1));
        setCurReturns(apiData.filter(item => item.lineType == 10 && item.reportingPeriod == 1));
        setPrevReturns(apiData.filter(item => item.lineType == 10 && item.reportingPeriod == 2));

        try{
          if(index==0){}else{
            setPrevSales(revenueTotal.filter(item => item.reportingPeriod == 2));
            setCurrentSales(revenueTotal.filter(item => item.reportingPeriod == 1));
          }
        }catch(e){
            console.log(e)
        }

      try{
        //current revenue total
        let totalRevenue = 0;
        for (const revenue of revenueTotal.filter(item => item.reportingPeriod == 1)) {
            totalRevenue += revenue["lineTotal"];
        }
        setSalesTotal(totalRevenue);
      }catch(e){
        setSalesTotal(0);
        console.log(e)
      }

      try{
        //previous revenue total
        let totalPrevRevenue = 0;
        for (const revenue of revenueTotal.filter(item => item.reportingPeriod == 2)) {
            totalPrevRevenue += revenue["lineTotal"];
        }
        setPrevSalesTotal(totalPrevRevenue);
      }catch(e){
        setSalesTotal(0);
        console.log(e)
      }

      try{
        //sales total
        let totalSalesTotal = 0;
        for (const revenue of salesTotall.filter(item => item.reportingPeriod == 1)) {
          totalSalesTotal += revenue["lineTotal"];
        }
        setCurrOrderTotals1(totalSalesTotal);
      }catch(e){
        setSalesTotal(0);
        console.log(e)
      }

      try{
        //prev sales total
        let totalSalesTotal = 0;
        for (const revenue of salesTotall.filter(item => item.reportingPeriod == 2)) {
          totalSalesTotal += revenue["lineTotal"];
        }
        setPrevOrderTotals1(totalSalesTotal);
      }catch(e){
        setSalesTotal(0);
        console.log(e)
      }

      setSalesByCategory(apiData.filter(item => item.lineType == 5 && item.reportingPeriod == 1));
      setBrandData(apiData.filter(item => item.lineType == 6 && item.reportingPeriod == 1));

      try{

        //BRANDS
        var cities = apiData.filter(item => item.lineType == 6 && item.reportingPeriod == 1);
        cities = cities.sort((a,b)=> b.lineTotal - a.lineTotal);
        const newCities = cities.map(item=>{
         const newItem = {"arg": item["lineName"],"val": item["lineTotal"]}
         return newItem;
        })
        setCityData(newCities)
       }catch(e){
         setCityData([]);
         console.log(e)
       }

       try{
        // PRODUCTS
      var returnsGraph = apiData.filter(item => item.lineType == 7 && item.reportingPeriod == 1);
      returnsGraph.sort((a, b) => b.lineTotal - a.lineTotal);
      const newList2 = returnsGraph.slice(0, 5);
      setProductTotals(newList2);
      }catch(e){
        setProductTotals([]);
        console.log(e)
      }


      /*
      setCashOnHand1(apiData["reportCashOnHand"][0]["cashBalance"] ?? 0);
      setBankBalance1(apiData["reportCashOnHand"][0]["bankBalance"] ?? 0);
      setCurrOrderTotals1(apiData["salesOrderTotals"][0]["currOrdTotals"] ?? 0);
      setPrevOrderTotals1(apiData["salesOrderTotals"][0]["prevOrdTotals"] ?? 0);





      try{
        // sales totals
      var pie_graph_data = apiData["salesGroupTotals"];
      pie_graph_data.sort((a, b) => b.categoryTotal - a.categoryTotal);
      const otherTotal = pie_graph_data.slice(4).reduce((acc, curr) => acc + curr.categoryTotal, 0);
      const newList = pie_graph_data.slice(0, 4);
      newList.push({ "reportCategory": t("others"), "categoryTotal": otherTotal });


      }catch(e){
        setSalesByCategory([]);
        console.log(e)
      }
*/
    }

    )
  }


  const onTabClick = useCallback((e) => {
    const { index, period } = ANALYTICS_PERIODS[e.addedItems[0]];
    setTabIndex(index);
    setDateRange(period.split('/'));
    setDateRange2(period.split('/'));
    if(index==0){
      update2(4)
    }else{
      update2(index)
    }

    setIsLoading(true);
  }, []);

  useEffect(() => {
    setTabsWidth(isXSmall ? 150 : 'auto');
  }, []);

  return (
    <ScrollView className='view-wrapper-scroll'>
      <ToolbarAnalytics
      showOthers={false}
        title={t('Dashboard')}
        additionalToolbarContent={
          <Item
            location='before' visible={false}
          >
            <Tabs
              width={tabsWidth}
              scrollByContent
              showNavButtons={false}
              dataSource={items}
              selectedIndex={tabIndex}
              onSelectionChanged={onTabClick}
            />
          </Item>
        }
      >
          <div className='cards '>
          <RevenueTotalTicker title={t("revenue")} showPercentage subtitle={euroFormat(prevSalesTotal)} value={salesTotal}
          percent={parseFloat(((salesTotal - prevSalesTotal) / prevSalesTotal * 100).toFixed(1))} />
          <LeadsTicker title={t("salesOrders")} showPercentage subtitle={euroFormat(prevOrderTotals1)} value={currOrderTotals1}
          percent={parseFloat(((currOrderTotals1 - prevOrderTotals1) / prevOrderTotals1 * 100).toFixed(1))}/>
          </div>
{
  /* // className='compact' 4'lü görünüm için
   <OpportunitiesTicker showPercentage subtitle='' value={cashOnHand1} percent={0} />
   <ConversionTicker showPercentage subtitle='' value={bankBalance1} percent={0} />
  */
}


        <div className='cards normal'>
        {tabIndex == 1 || tabIndex == 0 ? (
            <BarGraph title={t("revenue")} salesData1={currentSales} salesData2={prevSales} index={tabIndex}></BarGraph>
          ) : (
            <SalesGraph title={t("revenue")} salesData1={currentSales} salesData2={prevSales} index={tabIndex}></SalesGraph>
          )}
        <DashboardChart1 data={customers}></DashboardChart1>
        <RevenueSnapshotCard title={t("topCategories")} datasource={salesByCategory} />
        <ReturnsGraph title={t("topProducts")} returnsData={productTotals}></ReturnsGraph>
        <CitiesGraph title={t("topBrands")} cityData={cityData}></CitiesGraph>
        <SalesGraph title={t("returnComparison")} salesData1={curReturns} salesData2={prevReturns} index={tabIndex}></SalesGraph>


</div>

{
  /*
<div className='cards normal'>
        <SalesGraph title={t("Returns Comparison")} salesData1={curReturns} salesData2={prevReturns} index={tabIndex}></SalesGraph>
        <DashboardChart1 data={customers}></DashboardChart1>
        <RevenueSnapshotCard title={t("topCategories")} datasource={salesByCategory} />
        <CitiesGraph title={t("topBrands")} cityData={cityData}></CitiesGraph>
        <DashboardChart2 data={sellers}></DashboardChart2>
        <ReturnsGraph title={t("topProducts")} returnsData={productTotals}></ReturnsGraph>

        </div>
  */
}



{
  /*

  <RevenueCard datasource={currentSales} />
  <br></br>
        <div className=' normal'>
        <DailyCard datasource={dailyReportData} />
        </div>
  */
}


      </ToolbarAnalytics>
      <LoadPanel container='.content' visible={isLoading} position={{ of: '.layout-body' }} />
    </ScrollView>
  );
};