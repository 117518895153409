
import React, { useEffect, useState } from 'react';
import { Button, Popup, SelectBox, TextBox } from 'devextreme-react';
import { useTranslation } from 'react-i18next';
import notify from 'devextreme/ui/notify';
import { campaignGroupInsertRequest } from '../../../api/requests';
import { status_options, status_selectbox } from '../../../shared/status-selectbox';

export const CampaignGroupAddPopup = ({visible, onHiding, refresh, campaignGroup}) => {
  const [newCampaignGroup, setNewCampaignGroup] = useState(campaignGroup);
  const {t} = useTranslation();

  useEffect(() => {
    setNewCampaignGroup(campaignGroup);
    setSelectedStatus(campaignGroup?.groupStatus);
  }, [visible]);

  const updateField = (key) => (value) => {
    setNewCampaignGroup(prev => ({
      ...prev,
      [key]: value
    }));
  };

  function onSaveClick(){
    campaignGroupInsertRequest(newCampaignGroup).then((res)=>{
      if(res.resCode == 200){
        refresh();
        notify(t("successfull"),'success');
        onHiding();

      }else{
        notify(res.resMessage,'error');
      }
    });
  }

  function onCancelClick(){
    onHiding();
  }

  const [selectedStatus, setSelectedStatus] = useState<number>(0);

  const statusChange = (e: any) => {
    setSelectedStatus(e.value);
    updateField('groupStatus')(e.value);
  };

  return (
    <Popup
    visible={visible}
    onHiding={onHiding}
    width={500}
    height={"auto"}
    titleRender={()=>(
      <div className="flex flex-row justify-between py-2">
        <div className="flex flex-row">
        <Button icon="close" stylingMode="text" onClick={onHiding}></Button>
        <p className="ml-2 mt-0.5 justify-center font-bold text-lg">{campaignGroup.groupRef == 0 ? t("new") : campaignGroup.groupName }</p>
        </div>


      </div>
    )}
    contentRender={()=>
    (
      <div className='flex flex-col gap-y-3 p-2'>

        <div className='flex justify-between'>
        <div className='flex w-48'>
                <SelectBox
                      dataSource={status_options}
                      value={selectedStatus}
                      defaultValue={1}
                      displayExpr={(item) => t(item.label)}
                      valueExpr="value"
                      onValueChanged={statusChange}
                      itemRender={status_selectbox}
                      searchEnabled={false}
                      readOnly={newCampaignGroup?.groupRef == 0}
                      />
        </div>

        <div className='flex flex-row gap-x-2'>

            <Button text={t("save")} icon='save' stylingMode='contained' type='default' onClick={onSaveClick} />
            <Button text={t("cancel")} icon='close' stylingMode='contained' style={{backgroundColor:"gray"}} type='default' onClick={onCancelClick}/>
        </div>
        </div>

          <TextBox
            label={t("groupName")}
            value={newCampaignGroup?.groupName}
            onValueChange={updateField('groupName')}
          />

          <TextBox
            value={newCampaignGroup?.groupCode}
            onValueChange={updateField('groupCode')}
            label={t("groupCode")}
          />

          <TextBox
            value={newCampaignGroup?.comment}
            onValueChange={updateField('comment')}
            label={t("comment")}
          />
    </div>
    )}>

    </Popup>
  );
};
