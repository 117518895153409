import React, { useState, useEffect, useCallback, useRef } from 'react';
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import '../../theme/grid.scss';
import DataGrid, {
  Sorting, Selection, HeaderFilter, Scrolling, SearchPanel,
  ColumnChooser, Export, Column, Toolbar, Item, LoadPanel,
  DataGridTypes,
  Editing,
  Lookup,
  FilterPanel,
  FilterBuilderPopup,
  DataGridRef
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import DropDownButton, { DropDownButtonTypes } from 'devextreme-react/drop-down-button';
import { CampaignModel, ContactStatus as ContactStatusType } from '../../types/crm-contact';
import { ContactStatus } from '../../components';
import { newCampaignGroupModel, newCampaignModel} from '../../shared/constants';
import {  campaignGroupDeleteRequest, campaignGroupsListRequest, generalCampaignDeleteRequest, generalCampaignPostRequest, generalCampaignsRequest,} from '../../api/requests';
import { useTranslation } from 'react-i18next';
import CampaignPopup from '../../components/library/general-campaigns-detail/popup';
import notify from 'devextreme/ui/notify';
import { DateBox } from 'devextreme-react';
import { campaignListStatusFromSession, campaignListStatusToSession } from '../../api/sessions';
import { appInfo } from '../../app-info';
import { customerSelectionTypes } from '../../shared/select-boxes';
import { confirm } from 'devextreme/ui/dialog';
import { CampaignGroupAddPopup } from '../../components/library/general-campaign-groups/addPopup';



export const GeneralCampaignGroups = () => {
  const { t } = useTranslation();
  const [dataSet, setDataSet] = useState();
  const [rowData, setRowData] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const [addPopupVisible, setAddPopupVisible] = useState(false);

  const changeNewPopupOpen = useCallback(() => {
    setAddPopupVisible(!addPopupVisible);
  }, [addPopupVisible]);



  function mainRefresh(){

    campaignGroupsListRequest().then((res)=>{
        setDataSet(res.dataSet);
    })

  }

  useEffect(()=>{
    mainRefresh();
  },[])

  const handleContentReady = (e) => {
    setRowCount(e.component.totalCount());
  };


  const onRowClick = useCallback(({ data,rowIndex }: DataGridTypes.RowClickEvent) => {
    setRowData(data);
    setAddPopupVisible(true);

  }, []);

  const onRowRemoving = (e) => {
    const row = e.data;

    return campaignGroupDeleteRequest(row)
        .then((response) => {
            if (response.resCode == 200) {
                notify(t("successfull"), "success", 1000);
            } else {
                notify(response.resMessage, "error", 2500);
                mainRefresh();
                throw new Error(response.resMessage);
            }
        })
        .catch((error) => {
            console.error('Error deleting product:', error);
        });
  };


  return (
    <div className='view crm-contact-list'>
      <div className='view-wrapper view-wrapper-contact-list list-page'>
        <DataGrid
          className='grid theme-dependent'
          height='100%'
          dataSource={dataSet}
          keyExpr={"groupRef"}
          onContentReady={handleContentReady}
          onRowRemoving={onRowRemoving}
          onRowClick={onRowClick}
          focusedRowEnabled
          editing={{
            mode: 'row',
            allowDeleting: true,
        }}>
          <Editing texts={{
                        editRow: t("edit"),
                        deleteRow: t("delete"),
                        confirmDeleteMessage: t("confirmDeleteMessage"),
                        confirmDeleteYes: t("yes"),
                        cancelRowChanges: t("no"),
                    }}  />

          <LoadPanel />
          <SearchPanel visible placeholder={t("customerSearch")}  />
          <ColumnChooser title={t("columnChooser")} enabled />
          <Export enabled allowExportSelectedData formats={appInfo().exportFormats} />
          <HeaderFilter visible />
          <Sorting mode='multiple' />
          <Scrolling mode='virtual' />
          <Selection selectAllMode='allPages' showCheckBoxesMode='always' mode='multiple' />
          <FilterPanel visible={true} />
          <FilterBuilderPopup width={"50%"} height={"50%"} title={t("filterBuilder")} />

          <Toolbar>

          <Item name='searchPanel' location='before'  />

          <Item
              location='before'
              locateInMenu='auto'
              showText='inMenu'
              widget='dxButton'
            >
              <Button
                icon='refresh'
                hint={t("refresh")}
                stylingMode='text'
                onClick={mainRefresh}
              />
            </Item>

            <Item name='columnChooserButton' location='before' />

            <Item name='exportButton' location='before' />

            <Item location="before">
                <DropDownButton
                    visible={selectedRows.length>0}
                    text={"("+ selectedRows.length+") "+ t("transactions")}
                    icon="menu"
                    width={"175"}
                    items={[
                        { text: t("deleteSelected"), icon: 'trash', action: 'deleteSelected' },
                      ]}
                    onItemClick={(e) => {
                        if(e.itemData.action=="deleteSelected"){
                //            deleteSelected();
                        }
                      }}
                />
            </Item>

            <Item location='center' locateInMenu='auto'>
                {rowCount} {t("records")}
            </Item>

            <Item location='after' locateInMenu='auto'>
              <Button
                icon='plus'
                text={t("new")}
                type='default'
                stylingMode='contained'
                visible={true}
                onClick={()=>{
                    setRowData(JSON.parse('{"groupRef":0, "groupName":"", "groupCode":"", "groupStatus":1, "comment":""}'))
                    setAddPopupVisible(true);
                }}
              />
            </Item>

          </Toolbar>

          <Column
            dataField='groupStatus'
            caption={t("status")}
            hidingPriority={18}
            width={100}
            cellRender={ContactStatus}
          />

            <Column
            dataField='groupCode'
            caption={t("groupCode")}
            hidingPriority={19}
            width={150}
          />

            <Column
            dataField='groupName'
            caption={t("groupName")}
            hidingPriority={18}
          />

            <Column
            dataField='comment'
            caption={t("comment")}
            hidingPriority={16}
          />

        </DataGrid>

        <CampaignGroupAddPopup visible={addPopupVisible} onHiding={changeNewPopupOpen} refresh={mainRefresh} campaignGroup={rowData}/>

      </div>
    </div>
  );
};




type FilterContactStatus = ContactStatusType | 'active';


const onExporting = (e: DataGridTypes.ExportingEvent) => {
  if (e.format === 'pdf') {
    const doc = new JsPdf();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
    }).then(() => {
      doc.save('Campaigns.pdf');
    });
  } else {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Page1');

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Campaigns.xlsx');
      });
    });
    e.cancel = true;
  }
};

const dropDownOptions = { width: 'auto',  };